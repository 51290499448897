<template>
  <div
    class="date-picker d-flex align-center"
    v-shortkey="shortkeys"
    @shortkey="handleShortkeys"
  >
    <v-btn
      class="ml-3"
      outlined
      color="primary"
      width="80px"
      height="40px"
      :disabled="isProcessing"
      @click="setTodayDate"
    >
      Dzisiaj
    </v-btn>
    <v-btn
      text
      class="ml-2"
      x-small
      depressed
      max-width="32px"
      height="32px"
      color="primary"
      :disabled="isDateEmpty || isProcessing"
      @click="changeWeek()"
    >
      <v-icon>
        mdi-chevron-double-left
      </v-icon>
    </v-btn>
    <v-btn
      text
      x-small
      depressed
      max-width="32px"
      height="32px"
      color="primary"
      class="mr-1"
      :disabled="isDateEmpty || isProcessing"
      @click="changeOneDay()"
    >
      <v-icon>
        mdi-chevron-left
      </v-icon>
    </v-btn>
    <v-menu
      v-model="showDatePicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      :max-width="isRangePicker ? '700px' : '350px'"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          class="date-picker__input"
          :value="formattedDate"
          readonly
          single-line
          outlined
          hide-details
          v-bind="attrs"
          v-on="on"
          suffix="F10"
          :disabled="isProcessing"
          :append-icon="clearable && !isDateEmpty ? 'mdi-close' : undefined"
          @click.native="showDatePicker = !showDatePicker"
          @click:append="clearDate"
        >
          <template #prepend-inner>
            <Icon
              name="date"
              size="small"
              class="mr-2"
            />
          </template>
        </v-text-field>
      </template>
      <div
        class="date-picker__content"
        @click.capture="selectRange()"
      >
        <div
          class="d-flex flex-wrap justify-center"
          v-if="isRangePicker"
        >
          <v-date-picker
            v-model="localDate"
            :picker-date.sync="pickerDate[0]"
            range
            :events="localDate"
            event-color="primary"
            :min="getBoundaryDate(true)"
            :max="getBoundaryDate(false)"
            ref="firstPicker"
            class="firstPicker"
            v-bind="attributes.datePicker"
            @mouseover.native="predictedSelectRange($event, 1)"
            @mouseleave.native="clearPredictedSelectRange"
            @dblclick:date="changeDate"
          />
          <v-date-picker
            v-model="localDate"
            :picker-date.sync="pickerDate[1]"
            range
            :events="localDate"
            event-color="primary"
            :min="getBoundaryDate(true)"
            :max="getBoundaryDate(false)"
            ref="secondPicker"
            class="secondPicker"
            v-bind="attributes.datePicker"
            @mouseover.native="predictedSelectRange($event, 2)"
            @mouseleave.native="clearPredictedSelectRange"
            @dblclick:date="changeDate"
          />
        </div>
        <v-date-picker
          v-else
          v-model="localDate[0]"
          :picker-date.sync="pickerDate[0]"
          :events="localDate"
          event-color="primary"
          v-bind="attributes.datePicker"
          @dblclick:date="changeDate"
        />
        <div class="date-picker__presets d-flex justify-space-between pa-2">
          <v-autocomplete
            :value="activePreset"
            :items="presets"
            outlined
            hide-details
            dense
            @input="choosePreset"
          />
          <v-spacer />
          <div class="d-flex pl-2">
            <v-btn
              outlined
              color="primary"
              @click="closePicker"
            >
              Anuluj
            </v-btn>

            <v-btn
              class="base-hover ml-2"
              :disabled="!correctDate"
              color="primary"
              @click="changeDate"
            >
              Zatwierdź
            </v-btn>
          </div>
        </div>
      </div>
    </v-menu>
    <v-btn-toggle
      v-if="!hidePresets"
      v-model="selectedDay"
      @change="handleWeekDay"
      class="date-picker__week-days ml-2"
      active-class="date-picker__day--active"
      :style="{ pointerEvents: isProcessing ? 'none' : 'auto' }"
    >
      <v-btn
        v-for="(day, index) in weekDays"
        :key="day.label"
        :value="day.value"
        :disabled="isDaySelected(day)"
        small
        depressed
        hide-details
        class="date-picker__day"
        :class="{'date-picker__day--current' : day.current, 'date-picker__day--first': index === 0, 'date-picker__day--last': index + 1 === weekDays.length }"
      >
        {{ day.label }}<br>
        {{ getButtonDate(day) }}
      </v-btn>
    </v-btn-toggle>
    <v-btn
      text
      class="ml-1"
      x-small
      depressed
      max-width="32px"
      height="32px"
      color="primary"
      :disabled="isDateEmpty || isProcessing"
      @click="changeOneDay(true)"
    >
      <v-icon>
        mdi-chevron-right
      </v-icon>
    </v-btn>
    <v-btn
      text
      class="mr-2"
      x-small
      depressed
      max-width="32px"
      height="32px"
      color="primary"
      :disabled="isDateEmpty || isProcessing"
      @click="changeWeek(true)"
    >
      <v-icon>
        mdi-chevron-double-right
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
import DateTime from 'luxon/src/datetime'
import { mapActions, mapState } from 'vuex'
import { getTableMaximumDateRange, dateStringFormat } from '../../utils'
import attributes from '../../const/datePickerAttrributes'
import { toggleButtonsShortkeys } from '../../const/shortKeys'
import isEqual from 'lodash/isEqual'

const presets = Object.freeze([
  { name: 'day', span: 'day', type: 'current', text: 'Dzisiaj' },
  { name: 'day', span: 'day', type: 'previous', text: 'Wczoraj' },
  { name: 'day', span: 'day', type: 'next', text: 'Jutro' },
  { name: 'week', span: 'week', type: 'current', text: 'Aktualny tydzień' },
  { name: 'week', span: 'week', type: 'previous', text: 'Poprzedni tydzień' },
  { name: 'week', span: 'week', type: 'next', text: 'Kolejny tydzień' },
  { name: 'month', span: 'month', type: 'current', text: 'Aktualny miesiąc' },
  { name: 'month', span: 'month', type: 'previous', text: 'Poprzedni miesiąc' },
  { name: 'month', span: 'month', type: 'next', text: 'Kolejny miesiąc' },
  { name: 'month', span: 'quarter', type: 'current', text: 'Aktualny kwartał' },
  { name: 'month', span: 'quarter', type: 'previous', text: 'Poprzedni kwartał' },
  { name: 'year', span: 'year', type: 'current', text: 'Aktualny rok' },
  { name: 'year', span: 'year', type: 'previous', text: 'Poprzedni rok' },
  { name: 'threeYears', span: 'year', type: 'previous', text: 'Ostatnie 3 lata', spanStartOf: 'day', multiplier: 3 },
  { name: 'custom', span: 'custom', type: 'current', text: 'Własny zakres' },
])

const presetsConstraints = Object.freeze([
  { span: 'custom', minRange: 0 },
  { span: 'day', minRange: 1 },
  { span: 'week', minRange: 7 },
  { span: 'month', minRange: 31 },
  { span: 'quarter', minRange: 92 },
  { span: 'year', minRange: 366 },
  { span: 'threeYears', minRange: 1095 },
])

const shortkeys = {
  f10: ['f10'],
  ...toggleButtonsShortkeys
}

export default {
  props: {
    tableName: {
      type: String,
      required: true
    },
    hidePresets: {
      type: Boolean,
      default: false
    },
    disableFetch: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    showDatePicker: false,
    localDate: [],
    pickerDate: [],
    selectedDay: '',
    startSelecting: false,
    attributes,
    shortkeys
  }),
  computed: {
    ...mapState({
      sidebarSize: state => state.layout.sidebar.size,
    }),
    tableDateRange () {
      return this.$store.state[this.tableName].dateRange
    },
    isProcessing () {
      return this.$store.state[this.tableName]?.isProcessing || false
    },
    presets () {
      const activeSpans = presetsConstraints
        .filter(constraint => this.maximumDateRange >= constraint.minRange)
        .map(constraint => constraint.span)

      const availablePresets = presets.filter(preset => activeSpans.includes(preset.name))

      const formattedPresets = availablePresets.map(preset => {
        const multiplier = preset.multiplier || 1
        const { span, type, text } = preset
        if (span === 'custom') return { text, disabled: true }
        const offsets = { previous: -1, current: 0, next: 1 }

        const activePeriod = DateTime.local().plus({ [`${span}`]: offsets[type] * multiplier })
        const endPeriod = multiplier === 1 ? activePeriod.endOf(span) : DateTime.local()
        const spanStartOf = preset.spanStartOf || span
        const presetValue = [activePeriod.startOf(spanStartOf), endPeriod].map(v => v.toISODate())
        return { text, value: presetValue }
      })

      return formattedPresets
    },
    activePreset () {
      const { localDate } = this
      const currentDate = localDate.length === 1 ? Array(2).fill(...localDate) : localDate.sort()
      return this.presets.find(preset => isEqual(preset.value, currentDate) || !preset.value)
    },
    maximumDateRange () {
      return getTableMaximumDateRange(this.tableName)
    },
    isRangePicker () {
      return this.maximumDateRange !== 1
    },
    isDateEmpty () {
      return !this.tableDateRange.length
    },
    weekDays () {
      const startOfaWeek = DateTime.fromISO(this.tableDateRange[0]).startOf('week')
      return ['Pon.', 'Wt.', 'Śr.', 'Czw.', 'Pt.', 'Sob.', 'Nie.'].map((weekday, index) => {
        const weekdayDate = startOfaWeek.plus({ day: index })
        const isToday = weekdayDate.hasSame(DateTime.local(), 'day')
        return {
          label: weekday,
          value: weekdayDate.toISODate(),
          current: isToday
        }
      })
    },
    parsedGlobalDate () {
      return this.tableDateRange.map((item) => DateTime.fromISO(item))
    },
    parsedLocalDate () {
      return this.localDate.map((item) => DateTime.fromISO(item))
    },
    correctDate () {
      return this.tableDateRange.join() !== this.localDate.join() &&
         (this.localDate.length || this.clearable)
    },
    formattedDate () {
      const date = [...this.parsedGlobalDate]
      if (!date.length) return 'Pełny zakres'
      const formatted = date.sort().map(item => {
        if (DateTime.local().hasSame(item, 'days')) {
          const isRange = date.length === 2 && date[0] !== date[1]
          let day = 'dzisiaj'
          if (!isRange) day += ` - ${dateStringFormat(DateTime.local())}`
          return day
        }
        return dateStringFormat(item)
      })

      return formatted.join(', ')
    }
  },
  watch: {
    showDatePicker (showPicker) {
      if (showPicker) {
        this.selectRange()
        this.localDate = [...this.tableDateRange]
      }
      this.startSelecting = showPicker
    },
    localDate (newDate) {
      this.pickerDate = [...newDate]
      if (!newDate?.length) return
      if (newDate[0] === newDate[1]) {
        this.localDate = [this.localDate[0]]
      }
      this.startSelecting = false
    },
    tableDateRange() {
      this.prepickDay()
    }
  },
  mounted () {
    this.prepickDay()
  },
  methods: {
    ...mapActions({
      setTableDate: function (dispatch, payload) {
        return dispatch(`${this.tableName}/setTableDate`, payload)
      },
      showSnackbar: 'snackbar/showSnackbar'
    }),
    prepickDay() {
      this.selectedDay = this.tableDateRange.length > 1 ? '' : this.tableDateRange[0]
    },
    getBoundaryDate (isMinBoundary, date = this.localDate[0]) {
      if (!date) return undefined
      const firstDay = DateTime.fromISO(date)
      const maxRange = isMinBoundary ? this.maximumDateRange * -1 : this.maximumDateRange
      return firstDay.plus({ day: maxRange }).toISODate()
    },
    handleShortkeys (event) {
      if (!this.sidebarSize) {
        switch (event.srcKey) {
          case 'f10':
            this.showDatePicker = !this.showDatePicker
            break
          case 'ctrll':
          case 'cmdl':
            this.changeOneDay()
            break
          case 'ctrlr':
          case 'cmdr':
            this.changeOneDay(true)
            break
        }
      }
    },
    nextWeek (currentDay) {
      const nextWeekStart = currentDay.endOf('week').plus({ days: 1 })
      const nextWeekEnd = nextWeekStart.endOf('week')
      return [nextWeekStart.toISODate(), nextWeekEnd.toISODate()]
    },
    prevWeek (currentDay) {
      const prevWeekStart = currentDay.startOf('week').minus({ days: 7 })
      const prevWeekEnd = prevWeekStart.endOf('week')
      return [prevWeekStart.toISODate(), prevWeekEnd.toISODate()]
    },
    choosePreset (preset) {
      this.startSelecting = false
      this.localDate = preset
      this.selectRange()
    },
    handleWeekDay () {
      if (this.selectedDay) {
        this.localDate = [this.selectedDay]
      }
      this.changeDate()
    },
    setTodayDate() {
      this.localDate = [DateTime.local().toISODate()]
      this.changeDate()
    },
    changeOneDay (inc) {
      this.localDate = this.parsedGlobalDate.map((item) => item.plus({ days: inc ? 1 : -1 }).toISODate())
      this.changeDate()
    },
    changeWeek (inc) {
      this.localDate = inc ? [this.nextWeek(this.parsedGlobalDate[this.parsedGlobalDate.length - 1])[0]]
        : [this.prevWeek(this.parsedGlobalDate[0])[0]]
      this.changeDate()
    },
    clearDate () {
      this.localDate = []
      this.changeDate()
    },
    changeDate () {
      const { localDate, disableFetch } = this
      if (localDate.join() !== this.selectedDay) {
        this.selectedDay = localDate.length > 1 ? '' : localDate[0]
      }
      this.setTableDate({ date: localDate, fetch: !disableFetch })
      this.closePicker()
    },
    isDaySelected (day) {
      return day.value === this.selectedDay || this.isDateEmpty
    },
    closePicker () {
      this.localDate = []
      this.showDatePicker = false
    },
    getButtonDate (button) {
      if (!button.value) return
      const [, month, day] = button.value.split('-')
      return `${day}.${month}`
    },
    selectRange () {
      setTimeout(() => {
        document.querySelectorAll('.v-date-picker-table td .v-btn').forEach((el) => {
          if (el.childNodes.length > 1) {
            el.classList.add('v-btn--highlight')
          } else {
            el.classList.remove('v-btn--highlight')
          }
        })
      }, 50)
    },
    predictedSelectRange (event, nr) {
      const path = event.composedPath()
      if (path.length > 17 && this.localDate.length < 2 && !this.startSelecting) {
        // 17 is probably because if you hover over day tile the length value is 18, tricky idea tbh :/
        const hovered = Number(path.find((item) => item.localName === 'td').innerText)
        const { firstPicker, secondPicker } = this.$refs
        const selected = DateTime.local(nr < 2 ? firstPicker.tableYear : secondPicker.tableYear,
          nr < 2 ? firstPicker.tableMonth + 1 : secondPicker.tableMonth + 1,
          hovered
        )
        const start = this.parsedLocalDate[0] < selected ? this.parsedLocalDate[0] : selected
        const end = this.parsedLocalDate[0] < selected ? selected : this.parsedLocalDate[0];

        ['firstPicker', 'secondPicker'].forEach((picker) => {
          const calendar = this.$refs[picker]
          const range = [...Array(32).keys()].filter((day) => {
            const date = DateTime.local(calendar.tableYear, calendar.tableMonth + 1, day)
            return date >= start && date <= end
          })
          const selectors = document.querySelectorAll(`.${picker} .v-date-picker-table td`)
          selectors.forEach((el) => {
            el.classList.remove('predicted-highlight')
            if (range.includes(Number(el.innerText))) {
              el.classList.add('predicted-highlight')
            }
          })
        })
      }
    },
    clearPredictedSelectRange () {
      const elements = document.querySelectorAll('.v-date-picker-table td')
      elements.forEach((el) => {
        el.classList.remove('predicted-highlight')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.date-picker__input::v-deep .v-input__append-inner {
  margin-top: 9px !important;
}

.date-picker__input ::v-deep .v-input__control > .v-input__slot {
  margin-bottom: 0 !important;
}

</style>
