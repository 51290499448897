<template>
  <hsc-window-style-white>
    <hsc-window
      :title="modalTitle"
      position-hint="-50 / -50"
      :close-button="true"
      :is-open.sync="showModal"
      class="floating-popup moving-popup"
      :style="`z-index: ${dialogZIndex}`"
      @move-start="updateDialogIndex"
    >
      <v-card
        min-width="545px"
        max-width="545px"
        color="white"
        light
      >
        <v-tabs
          v-model="currentCallTabIndex"
          class="pb-4"
          show-arrows
          @change="setCallTouchedState"
        >
          <v-tabs-slider color="teal lighten-3" />

          <v-tab
            v-for="call in calls"
            :key="call.callEntryId"
            :disabled="tabsDisabled"
          >
            <v-badge
              :value="!call.touched"
              dot
            >
              <div class="d-flex align-center">
                <span>{{ call.phoneNumber }}</span>
                <v-icon
                  size="20"
                  color="red"
                  class="ml-2"
                  @click="removeCall(call.callEntryId)"
                >
                  mdi-close
                </v-icon>
              </div>
            </v-badge>
          </v-tab>
        </v-tabs>
        <v-card-text
          v-if="currentTabCallData"
          class="pt-0"
        >
          <v-row
            v-if="currentTabCallData.lastCall"
            class="mb-3"
          >
            <v-col cols="6">
              <span class="label">Odbiorca</span>
              <div>{{ currentTabCallData.lastCall.user || 'Brak' }}</div>
            </v-col>
            <v-col cols="6">
              <span class="label">Data</span>
              <div>{{ formatDate(currentTabCallData.lastCall.createdAt) || 'Brak' }}</div>
            </v-col>
            <v-col cols="12">
              <span class="label">Notatka</span>
              <div class="notes-wrapper">
                {{ currentTabCallData.lastCall.notes || 'Brak' }}
              </div>
            </v-col>
          </v-row>
          <template v-if="currentTabCallData.clientsRelatedToPhoneNumber.length">
            <div
              v-shortkey="selectActiveClientShortkeys"
              @shortkey="manageActiveClient"
              class="client-list"
            >
              <v-btn
                v-for="(client, index) in currentTabCallData.clientsRelatedToPhoneNumber"
                :ref="`button${index}`"
                :key="client.id"
                class="client-button mb-4"
                :class="{ 'not-selected': index !== currentTabCallData.activeIndex }"
                color="primary"
                max-width="100%"
                dark
                block
                @click="openClientPanel(client)"
              >
                {{ client.name }}
              </v-btn>
            </div>
          </template>

          <template v-else>
            <v-radio-group
              v-model="currentTabCallData.isProspectMode"
              class="pt-0 mt-0"
              row
              @change="$refs.form.resetValidation()"
            >
              <v-radio
                label="Prospekt"
                :value="true"
              />
              <v-radio
                label="Istniejacy klient"
                :value="false"
              />
            </v-radio-group>

            <v-form
              ref="form"
              class="custom-form"
              @submit.prevent="performSubmitAction"
            >
              <template v-if="currentTabCallData.isProspectMode">
                <v-textarea
                  v-model="currentTabCallData.prospectNotes"
                  class="my-2"
                  placeholder="Wpisz notatkę do rozmowy"
                  label="Notatka"
                  outlined
                  validate-on-blur
                  :rules="[rules.required]"
                  :rows="3"
                  hide-details
                  auto-grow
                  light
                />

                <v-btn
                  color="primary"
                  class="base-hover d-block ml-auto"
                  type="submit"
                  :loading="prospectProcessing"
                >
                  Stwórz prospekt
                </v-btn>
              </template>
              <template v-else>
                <v-autocomplete
                  outlined
                  :items="currentTabCallData.clientsFromQuery"
                  :rules="[rules.required]"
                  label="Szukaj klienta"
                  v-model="currentTabCallData.chosenClient"
                  item-text="name"
                  return-object
                  hide-details
                  :loading="clientProcessing"
                  placeholder="Szukaj klienta"
                  validate-on-blur
                  :search-input.sync="currentTabCallData.clientQuery"
                  :filter="() => currentTabCallData.clientsFromQuery"
                  @keydown="searchForClientsWithQuery"
                  dense
                />

                <v-input
                  :value="currentTabCallData.chosenClient.id"
                  :rules="[rules.required]"
                  hide-details
                  class="validated-label mb-6"
                >
                  <div class="mr-1">
                    Wybrany klient:
                  </div>
                  <span class="font-weight-medium">{{ currentTabCallData.chosenClient.name || '-' }}</span>
                </v-input>

                <v-text-field
                  v-model="currentTabCallData.contactName"
                  placeholder="Wpisz imię i nazwisko kontaktu"
                  label="Imię i nazwisko kontaktu"
                  class="mb-4"
                  outlined
                  hide-details
                />

                <v-textarea
                  v-model="currentTabCallData.clientNotes"
                  class="my-2"
                  placeholder="Wpisz notatkę do rozmowy"
                  label="Notatka"
                  outlined
                  :rules="[rules.required]"
                  :rows="3"
                  hide-details
                  auto-grow
                  light
                />

                <v-btn
                  color="primary"
                  class="base-hover d-block ml-auto"
                  type="submit"
                  :disabled="!currentTabCallData.chosenClient.id"
                  :loading="clientProcessing"
                >
                  Dodaj do klienta
                </v-btn>
              </template>
            </v-form>
          </template>
        </v-card-text>
      </v-card>
    </hsc-window>
  </hsc-window-style-white>
</template>

<script>
import { openNewWindow } from '../../utils'
import rules from '../../utils/validators'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { mapState, mapActions } from 'vuex'
import { selectActiveClientShortkeys, shortkeysNavigateBoundaries } from '../../const/shortKeys'
import humps from 'humps'

export default {
  data: () => ({
    dialogZIndex: 10,
    rules,
    selectActiveClientShortkeys,
    currentCallTabIndex: 0,
    calls: []
  }),
  channels: {
    CRMCallNotifyChannel: {
      received(data) {
        this.setNewCall(data)
      }
    }
  },
  computed: {
    ...mapState({
      token: state => state.auth.token,
      prospectProcessing: state => state.prospects.isProcessing,
      clientProcessing: state => state.client.isProcessing,
      clientsProcessing: state => state.clients.isProcessing
    }),
    tabsDisabled() {
      return this.prospectProcessing || this.clientProcessing || this.clientsProcessing
    },
    showModal: {
      get() {
        return this.$store.state.clientSearchDialog.isActiveModal
      },
      set(showModal) {
        this.setModalActivity(showModal)
        if (!showModal) {
          // Clearing all calls
          this.calls = []
          this.currentCallTabIndex = 0
          if (this.$refs.form) this.$refs.form.resetValidation()
        } else {
          this.updateDialogIndex()
        }
      }
    },
    modalTitle() {
      return 'Wyszukiwarka klientów'
    },
    currentTabCallData() {
      return this.calls[this.currentCallTabIndex]
    }
  },
  mounted() {
    this.$cable.connection.connect(`${process.env.VUE_APP_WSS_BASE_URL}?Authorization=${this.token}`)
    this.$cable.subscribe({ channel: 'CRMCallNotifyChannel' })
  },
  methods: {
    ...mapActions({
      showSnackbar: 'snackbar/showSnackbar',
      createProspect: 'prospects/editItem',
      getClientsByQuery: 'client/getClientsByQuery',
      addNewContact: 'clients/addNewItem',
      setModalActivity: 'clientSearchDialog/setModalActivity',
      updateDialogIndex: function(dispatch) {
        this.dialogZIndex = this.$store.state.layout.dialogTopIndex
        return dispatch('layout/updateDialogIndex')
      }
    }),
    searchForClientsWithQuery: debounce(function() {
      if (this.currentTabCallData.clientQuery && this.currentTabCallData.clientQuery.length > 2) {
        this.getClientsByQuery(this.currentTabCallData.clientQuery).then(clients => {
          this.currentTabCallData.clientsFromQuery = clients
        })
      }
    }, 500),
    setNewCall(data) {
      this.showModal = true
      class NewCall {
        constructor(data) {
          this.callEntryId = data.callEntryId
          this.phoneNumber = data.phoneNumber
          this.callStart = new Date().toISOString()
          this.clientsRelatedToPhoneNumber = data.clients
          this.lastCall = data.lastCall
          this.chosenClient = { id: null }
          this.clientQuery = ''
          this.clientNotes = ''
          this.contactName = ''
          this.prospectNotes = ''
          this.isProspectMode = true
          this.activeIndex = 0
          this.touched = !data.areAnyCalls
        }
      }
      const camelizedData = humps.camelizeKeys(data)
      this.calls.push(new NewCall({ ...camelizedData, areAnyCalls: this.calls.length }))
    },
    openClientPanel(client) {
      const path = `/clients/${client.id}`
      const { contact_id: contactId } = client
      const query = {
        phoneNumber: this.currentTabCallData.phoneNumber,
        callStart: this.currentTabCallData.callStart,
        contactId,
        callEntryId: this.currentTabCallData.callEntryId,
        immediateInit: true
      }
      openNewWindow.call(this, { path, query })
    },
    manageActiveClient(event) {
      if (event.srcKey === 'enter') {
        this.openClientPanel(this.currentTabCallData.clientsRelatedToPhoneNumber[this.currentTabCallData.activeIndex])
      } else {
        const { activeIndex, clientsRelatedToPhoneNumber } = this.currentTabCallData
        this.calls[this.currentCallTabIndex].activeIndex = shortkeysNavigateBoundaries(
          event.srcKey,
          activeIndex,
          clientsRelatedToPhoneNumber,
          ['up'],
          ['down']
        )
      }
    },
    performSubmitAction() {
      if (this.currentTabCallData.isProspectMode) {
        this.addProspect()
      } else {
        this.addToClient()
      }
    },
    addProspect() {
      const params = {
        notes: this.currentTabCallData.prospectNotes,
        direction: 'Wychodzące',
        callStart: new Date().toISOString(),
        phoneNumber: this.currentTabCallData.phoneNumber
      }
      this.createProspect({ params, id: this.currentTabCallData.callEntryId, tableName: 'callEntries' }).then(() => {
        this.calls = this.calls.filter(call => call.callEntryId !== this.currentTabCallData.callEntryId)
        if (!this.calls.length) this.showModal = false
      })
    },
    addToClient() {
      const names = this.currentTabCallData.contactName.split(' ')
      const params = {
        clientId: this.currentTabCallData.chosenClient.id,
        phoneNumber: this.currentTabCallData.phoneNumber,
        role: 'Inny',
        lastName: names.pop() || null,
        firstName: names.join(' ') || null
      }
      this.addNewContact({ params, endpoint: 'contacts' }).then(data => {
        const params = {
          clientId: this.currentTabCallData.chosenClient.id,
          contactId: data.id,
          notes: this.currentTabCallData.clientNotes
        }
        this.createProspect({ params, id: this.currentTabCallData.callEntryId, tableName: 'callEntries' }).then(() => {
          this.openClientPanel(cloneDeep({ ...this.currentTabCallData.chosenClient, contactId: data.id }))
          this.calls = this.calls.filter(call => call.callEntryId !== this.currentTabCallData.callEntryId)
          if (!this.calls.length) this.showModal = false
        })
      })
    },
    formatDate(date) {
      if (!date) return null
      return new Date(date).toLocaleString()
    },
    setCallTouchedState() {
      if (!this.calls[this.currentCallTabIndex]) return
      this.calls[this.currentCallTabIndex].touched = true
    },
    removeCall(callEntryId) {
      this.calls = this.calls.filter(call => call.callEntryId !== callEntryId)
      if (!this.calls.length) this.showModal = false
    }
  }
}
</script>

<style lang="scss" scoped>
.client-button::v-deep .v-btn__content {
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  display: block;
  text-align: center;
}

.not-selected {
  opacity: 0.7;
}

.client-list {
  max-height: 400px;
  overflow-y: scroll;
}

.label {
  font-weight: 800;
  font-size: 12px;
  display: block;
  color: $color-text-dark-grey;
}

.notes-wrapper {
  max-height: 100px;
  overflow-y: auto;
}

::v-deep .v-badge__wrapper {
  top: -5px;
  left: 5px;
}

::v-deep .v-badge__badge {
  height: 5px;
  width: 5px;
}

::v-deep .theme--light.v-icon:not(.v-icon--disabled) {
  font-size: 24px;
  color: $primary !important;
}

::v-deep .button {
  display: flex;
  align-items: center;
}

</style>
