<template>
  <div class="details-courses my-2">
    <div
      v-if="!list.length"
      class="px-6 details-attachments__name"
    >
      Brak historii płatności
    </div>
    <div
      v-for="(item, index) in list"
      :key="index"
      class="details__row--multiple"
    >
      <v-container fluid>
        <v-row>
          <v-col
            v-for="field in getFilteredFields(item)"
            :key="field.name"
            cols="12"
            sm="12"
            :md="field.col"
            class="py-1"
          >
            <DialogInput
              :field="field"
              :multiple="item"
              :detail="detail"
            />
          </v-col>
        </v-row>
        <v-row>
          <ActionButtons
            class="ml-n2"
            :actions="getResendActions(item)"
            :item="data"
            small
          />
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import DialogInput from '../../Forms/Inputs/DialogInput'
import ActionButtons from '../../Elements/ActionButtons.vue'

const resendActions = [
  { name: 'resendPaymentLink', component: 'GeneratePaymentLink', target: 'dialog', text: 'Wyślij link ponownie', mode: 'resend' },
  { name: 'regeneratePaymentLink', component: 'GeneratePaymentLink', target: 'dialog', text: 'Wygeneruj ponownie', mode: 'regenerate' }
]
export default {
  components: {
    DialogInput,
    ActionButtons,
  },
  props: {
    detail: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    list: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    resendActions
  }),
  methods: {
    getFilteredFields(data) {
      return this.detail.fields
        .filter((field) => !field.condition || field.condition(data))
    },
    getResendActions(linkSettlement) {
      const actions = [{ name: 'resendPaymentLink', component: 'GeneratePaymentLink', target: 'dialog', text: 'Wyślij link ponownie', mode: 'resend', linkSettlement }]
      if (linkSettlement.paymentLink.paymentLinkType === 'mail') actions.push({ name: 'regeneratePaymentLink', component: 'GeneratePaymentLink', target: 'dialog', text: 'Wygeneruj ponownie', mode: 'regenerate', linkSettlement })
      return actions
    }
  }
}
</script>
