<template>
  <hsc-window-style-white v-if="showDialog">
    <hsc-window
      :position-hint="dialogPosition"
      :close-button="true"
      :is-open.sync="showDialog"
      class="floating-popup"
      :width="dialogWidth"
      :max-height="windowHeight"
      :style="`z-index: ${dialogZIndex}`"
      @move-start="updateDialogIndex"
    >
      <template #title>
        <div class="d-flex justify-space-between w-100">
          <span>{{ dialogTitle }}</span>
          <v-btn
            v-if="isDialogExpendable"
            icon
            max-height="32"
            max-width="32"
            class="mr-2"
            @click="openNewWindow"
          >
            <v-icon color="#000">
              mdi-window-restore
            </v-icon>
          </v-btn>
        </div>
      </template>
      <v-fade-transition>
        <component
          v-if="loadComponent()"
          :is="loadComponent()"
          v-bind="dialog.data.props"
        />
      </v-fade-transition>
    </hsc-window>
  </hsc-window-style-white>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { openNewWindow } from '../../utils'

export default {
  data: () => ({
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
    dialogZIndex: 10,
  }),
  computed: {
    ...mapState({
      dialog: state => state.layout.dialog
    }),
    showDialog: {
      get: function () {
        return this.dialog.active
      },
      set: function (newValue) {
        if (!newValue) this.closeDialog()
        else this.updateDialogIndex()
      }
    },
    dialogTitle () {
      return this.dialog?.data?.text
    },
    isDialogExpendable () {
      return this.dialog?.data?.isExpendable
    },
    dialogWidth () {
      const { size: dialogSize } = this.dialog?.data || {}
      if (dialogSize === 'big') {
        return 1100
      } else if (!dialogSize || dialogSize === 'small') {
        return 550
      } else {
        return parseInt(dialogSize)
      }
    },
    dialogPosition () {
      // todo find a way to center vertically
      let top = 0
      if (this.windowHeight > 1000) top = 100
      if (this.windowHeight > 1200) top = 200
      const left = (this.windowWidth - this.dialogWidth) / 2
      return `${left}/${top}`
    }
  },
  mounted () {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
      this.windowHeight = window.innerHeight
    }
  },
  methods: {
    ...mapActions({
      closeDialog: 'layout/closeDialog',
      updateDialogIndex: function (dispatch) {
        this.dialogZIndex = this.$store.state.layout.dialogTopIndex
        return dispatch('layout/updateDialogIndex')
      },
    }),
    loadComponent () {
      const { component } = this.dialog?.data || {}
      if (component && !this.$options.components[component]) {
        this.$options.components[component] = () =>
          import(`@/components/Forms/${component}.vue`)
      }
      return component
    },
    openNewWindow () {
      const { component: form, text: title } = this.dialog?.data || {}
      openNewWindow.call(this, { name: 'form', params: { title, form } })
    }
  }
}
</script>

<style scoped>
::v-deep .button {
  display: flex;
  align-items: center;
}
</style>
